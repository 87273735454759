<template>
  <div>
    <input type="file" @change="handleFileChange">
    <PdfReader :src="pdfData" v-if="pdfData" :page="pageNumber"></PdfReader>
  </div>
</template>

<script>
import PdfReader from 'vue-pdf-reader';

export default {
  data() {
    return {
      pdfData: null,
      pageNumber: 1,
    };
  },
  components: {
    PdfReader,
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file && file.type === 'application/pdf') {
        const reader = new FileReader();
        reader.onload = () => {
          this.pdfData = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        this.pdfData = null;
      }
    },
  },
};
</script>
